let mod = 1;

const findNearest = (container, idx, cls) => {
  const predicate = (n) => n.classList.contains(cls);

  for (let i = 1; i < container.children.length; i++) {
    const j = i * mod;
    mod *= -1;

    if (idx + j < container.children.length && predicate(container.children[idx + j])) {
      return container.children[idx + j];
    } else if (idx - j > 0 && predicate(container.children[idx - j])) {
      return container.children[idx - j];
    }
  }
};

const photoGrid = (container) => {
  let portraits = [], landscapes = [];

  for (let i = 0; i < container.children.length; i++) {
    const node = container.children[i];
    const cl = node.classList;

    if (cl.contains('landscape')) {
      landscapes.push({ idx: i, node });
    } else if (cl.contains('portrait')) {
      portraits.push({ idx: i, node });
    }
  }

  // arrange portraits in an aesthetically pleasing way
  portraits.forEach(({ idx, node }) => {
    const nearestLandscape = findNearest(container, idx, 'landscape');

    if (nearestLandscape) {
      nearestLandscape.classList.toggle('shrink', true);
    }
  });
};

const layout = () => {
  document.querySelectorAll('[data-smuggy-layout]').forEach((node) => {
    photoGrid(node);
  });
};

export default layout;
