function loadImage(node) {
  const img = new Image();
  img.onload = () => {
    if (node.dataset.src) {
      node.src = img.src;
    }

    if (node.dataset.srcset) {
      node.srcset = img.srcset;
    }

    node.onload = () => {
      delete node.dataset.src;
      delete node.dataset.srcset;
    };
  };

  if (node.dataset.src) {
    img.src = node.dataset.src;
  }

  if (node.dataset.srcset) {
    img.srcset = node.dataset.srcset;
  }
}

const images = () => {
  const observer = new IntersectionObserver((items, observer) => {
    items.forEach((item) => {
      if (item.isIntersecting) {
        loadImage(item.target);
        observer.unobserve(item.target);
      }
    });
  }, {
    rootMargin: '0px 0px 25% 0px',
  });

  document.querySelectorAll('img[data-src], img[data-srcset]').forEach((node) => {
    observer.observe(node);
  });
};

export default images;
